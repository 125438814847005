import {
  loadDeviceIdFromLocalStorage,
  saveDeviceIdToLocalStorage,
} from '../state/localStorage';
import isMobile from 'ismobilejs';

export const getDeviceId = () => {
  let deviceId = loadDeviceIdFromLocalStorage();

  if (deviceId !== null) {
    return deviceId;
  }

  deviceId = generateDeviceId();
  saveDeviceIdToLocalStorage(deviceId);

  return deviceId;
};

const generateDeviceId = () => {
  const device =
    typeof window !== 'undefined' && window
      ? isMobile(window.navigator)
      : {
          apple: { device: true },
          android: { device: false },
          phone: false,
          tablet: false,
          any: true,
        };

  let deviceId = '';

  if (device.apple === true) {
    deviceId = 'apple-';
  } else if (device.android === true) {
    deviceId = 'android-';
  } else {
    deviceId = 'other-';
  }

  if (device.phone) {
    deviceId += 'phone-';
  } else if (device.tablet) {
    deviceId += 'tablet-';
  } else {
    deviceId += 'other-';
  }

  return deviceId + Math.random();
};
