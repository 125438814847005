/**
 *
 * @type {string}
 */
const LOCALSTORAGE_SIGN_UP_REQUEST_KEY = '___signupRequest';
const LOCALSTORAGE_DEVICE_KEY = '___device';
const _localStorage =
  typeof window !== `undefined` ? window.localStorage : null;

export const loadFromLocalStorage = (key) => {
  if (null !== _localStorage && null !== _localStorage.key(key)) {
    try {
      return JSON.parse(_localStorage.getItem(key));
    } catch (e) {
      console.error('Cannot load ' + key + ' from local storage:');
      _localStorage.removeItem(key);
      return null;
    }
  }

  return null;
};

export const saveToLocalStorage = (key, data) => {
  try {
    const serializedState = JSON.stringify(data);
    _localStorage.setItem(key, serializedState);
  } catch (e) {
    console.error('Cannot save ' + key + ' to local storage');
  }
};

export const removeFromLocalStorage = (key) => {
  try {
    _localStorage.removeItem(key);
  } catch (e) {
    console.error('Cannot remove ' + key + ' from local storage:');
  }
};

/**
 * Device id
 */
export const loadDeviceIdFromLocalStorage = () => {
  return loadFromLocalStorage(LOCALSTORAGE_DEVICE_KEY);
};

export const saveDeviceIdToLocalStorage = (data) => {
  saveToLocalStorage(LOCALSTORAGE_DEVICE_KEY, data);
};

/**
 * Sign up requests functions
 */
export const loadSignUpRequestFromLocalStorage = () => {
  return loadFromLocalStorage(LOCALSTORAGE_SIGN_UP_REQUEST_KEY);
};

export const saveSignUpRequestToLocalStorage = (data) => {
  saveToLocalStorage(LOCALSTORAGE_SIGN_UP_REQUEST_KEY, data);
};

export const removeSignUpRequestFromLocalStorage = () => {
  removeFromLocalStorage(LOCALSTORAGE_SIGN_UP_REQUEST_KEY);
};
